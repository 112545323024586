import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition, animation, } from '@angular/animations';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookie-notice',
  templateUrl: './cookie-notice.component.html',
  styleUrls: ['./cookie-notice.component.sass'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translate(100px,0)'}),
        animate('500ms 2s cubic-bezier(0.445, 0.050, 0.550, 0.950)', style({opacity: 1, transform: 'translate(0px,0)'}))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translate(0px,0)'}),
        animate('500ms cubic-bezier(0.445, 0.050, 0.550, 0.950)', style({opacity: 0, transform: 'translate(100px,0)'}))
      ])
    ])
  ]
})
export class CookieNoticeComponent implements OnInit {
  public showCookie: boolean;
  public CookieConsent: String;
  public showTick: boolean;

  constructor(private cookieService: CookieService) {
    this.showCookie = false;
    this.showTick = false;
  }

  ngOnInit() {
    this.checkCookie();
  }
  public checkCookie(): void {
    let cookie = this.cookieService.get("cookieConsent");
    console.log('Der wert vom Cookie ist: ' + this.cookieService.get('cookiecConsent'));
    if(cookie === "true"){
      this.showCookie = false;
    } else {
      this.showCookie = true;
    }
  }
  public setCookie(event?: any): void {
    document.cookie = "cookieConsent=true";
    console.log("setcooke Success!");
    this.showCookie = false
  }
}
