import { Component, OnInit, Renderer, Renderer2 } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router'
import { DeviceDetectorService } from 'ngx-device-detector';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { TimelineMax, Back, Power1, SlowMo } from 'gsap'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
  animations: [
    trigger('slideIn', [
      transition(':enter', [
        style({opacity: 0, transform: 'translate(-100px, 0)'}),
        animate('250ms', style({opacity: 1, transform: 'translate(0, 0)'}))
      ]),
      transition(':leave', [
        style({opacity: 1, transform: 'translate(0, 0)'}),
        animate('250ms', style({opacity: 0, transform: 'translate(100px, 0)'}))
      ]),
    ])
  ]
})
export class HeaderComponent implements OnInit {
  private fragment: string;
  private deviceInfo;
  public isMobile;
  public hamburgerIsOpen: boolean;
  public navItems = [
    {
      title: "Startseite",
      path: "hero"
    },
    {
      title: "Unternehmen",
      path: "unternehmen"
    },
    {
      title: "Leistungen",
      path: "leistungen"
    },
    {
      title: "Projekte",
      path: "projekte"
    },
    {
      title: "Kontakt",
      path: "kontakt"
    }
  ]
  constructor(private renderer: Renderer2, private route: ActivatedRoute, private router: Router, private deviceService: DeviceDetectorService) {}

  ngOnInit() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
   this.isMobile = this.deviceService.isMobile();
   this.hamburgerIsOpen = false;
  }

  public testFunc(event?: any) {
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
    try {
      const elem = document.querySelector('#' + this.fragment);
      elem.scrollIntoView({behavior: 'smooth' })
      event.preventDefault();
    } catch (e) { }
  }
  public hamburgerToggle(event: any):void {
    this.hamburgerIsOpen = !this.hamburgerIsOpen;
    this.faderInAnimation();
    if(this.hamburgerIsOpen == true) {
      this.renderer.addClass(document.body, 'menu-open')
    } else {
      this.renderer.removeClass(document.body, 'menu-open')
    }
  }
  public faderInAnimation() {
    const tl: TimelineMax = new TimelineMax();
    tl.fromTo('.MobileMenuItem', 1,
    {
      left:0, 
      opacity:1
    },
    {
      left:100,
      opacity:0.5
    });
  }
  public moveToAncherMobile() {
    this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
      });
    try {
      const elem = document.querySelector('#' + this.fragment);
      elem.scrollIntoView({block: 'start'});
      window.scrollBy(0, -140); 
      event.preventDefault();
      this.hamburgerIsOpen = false;
      this.renderer.removeClass(document.body, 'menu-open');
    } catch (e) { }
  }
}
