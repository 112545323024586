import { Component, OnInit } from '@angular/core';
import { TimelineMax, Back, Power1, SlowMo } from 'gsap'

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.svg',
  styleUrls: ['./logo.component.sass']
})
export class LogoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    setTimeout(() => {
      this.animateLogo();
    }, 600);
  }
  public animateLogo():void {
    const tl: TimelineMax = new TimelineMax()
    tl.staggerFromTo(".cls-1-1", 1,
      {
        opacity:0,
        y: 100,
        rotation: 15
      },
      {
        opacity:1,
        y: 0,
        rotation: 0
      },0.1);
    tl.staggerFromTo(".cls-2", 0.5,
      {opacity:0, x: 100},
      {opacity:1, x: 0},
    0.05);
    tl.fromTo(".cls-3", 1, {opacity:0}, {opacity:1});
  }
}
