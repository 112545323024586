import { Component, OnInit, AfterContentInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DirectusService } from 'src/app/_service/directus.service';

@Component({
  selector: 'app-projekt',
  templateUrl: './projekt.component.html',
  styleUrls: ['./projekt.component.sass']
})
export class ProjektComponent implements OnInit {
  public id: any;
  public project: any;
  public fileIds: any[];
  public images: any [];
  public showBox: boolean = false;
  public boxImg: string;
  deviceInfo = null;
  isMobile;
  constructor(private route: ActivatedRoute,private deviceService: DeviceDetectorService, private ds: DirectusService) {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.deviceInfo = this.deviceService.getDeviceInfo();
      this.isMobile = this.deviceService.isMobile();
    })
  }
  async ngOnInit() {
    this.project = await this.ds.getProjektById(this.id);
    window.scrollTo(0, 0);
    this.images = this.project.gallery;
  }




  public lightShow(e, el) {
    console.log(el);
    this.boxImg = el;
    this.showBox = !this.showBox;
    document.body.style.overflow = "hidden";

  }
  public disableLightBox(e, el) {
    this.showBox = false;
    document.body.style.overflow = "scroll";
  }
}
