import { Component, OnInit } from '@angular/core';
import { Router, Data } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DirectusService } from 'src/app/_service/directus.service';


export interface DataProject {
  data: [],
  public: boolean;
}
@Component({
  selector: 'app-startseite',
  templateUrl: './startseite.component.html',
  styleUrls: ['./startseite.component.sass']
})
export class StartseiteComponent implements OnInit {
  public isContactChecked: boolean;
  public projects: any[];
  public Mitarbeiter: any[];
  public showFromRES: boolean;
  public isFormSuccess: boolean;
  public loaderFormSpinner: boolean;
  deviceInfo = null;
  isMobile;
  FormContact = new FormGroup({
    contactName:        new FormControl('',Validators.required),
    contactMail:        new FormControl('', [Validators.required,Validators.email]),
    contactPhone:       new FormControl(''),
    contactBetreff:     new FormControl('',Validators.required),
    contactMessage:     new FormControl('',Validators.required),
  });
  public contactFormValid: any = {
    inputName: false,
    inputEmail: false,
    inputBetreff: false,
    inputNachricht: false
  };
  constructor(public router: Router, public http: HttpClient, private deviceService: DeviceDetectorService, private ds: DirectusService) {
    this.isContactChecked = false;
    this.showFromRES = false;
    this.isFormSuccess = false;
    this.loaderFormSpinner = false;
  }

  ngOnInit() {
    this.getPosts();
    this.getMitarbeiter();
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
  }

  public routeTo(event: any, id: any): void  {
    this.router.navigate(['/projekt', id]);
  }
  public async getPosts(){
    this.projects = await this.ds.getProjekte();
  }
  public async getMitarbeiter() {
    this.Mitarbeiter = await this.ds.getMitarbeiter();
  }
  public onSubmitForm() {
    if(this.FormContact.status === "VALID"){
      this.loaderFormSpinner = true;
      this.http.post<any>('https://architekt-kuschewski.de/directus/public/kuschewski/items/mail',[
        {
          name: this.FormContact.value.contactName,
          email: this.FormContact.value.contactMail,
          telefon: this.FormContact.value.contactPhone,
          betreff: this.FormContact.value.contactBetreff,
          nachricht: this.FormContact.value.contactMessage,
        }]).subscribe(
        (data) => {
          if(data.data.length >= 1) {
            this.showFromRES = true;
            this.isFormSuccess = true;
            this.loaderFormSpinner = false;
            this.isContactChecked = false;
            this.FormContact.reset();
            Object.keys(this.contactFormValid).forEach( i => this.contactFormValid = false);
          }else {
            console.log('error: ', data)
          }
        }
      ),(err) => {
        console.log('error:', err);
      }
    } else {
      console.log(this.FormContact);
      this.showFromRES = true;
      this.isFormSuccess = false;
      this.isContactChecked = false;

      ///Check Input "Ihr Name"
      this.contactFormValid.inputName = this.FormContact.controls.contactName.hasError('required');
      ///Check Input "Ihre Emailadresse"
      this.contactFormValid.inputEmail = this.FormContact.controls.contactMail.hasError('required');
      ///Check Input "Betreff"
      this.contactFormValid.inputBetreff = this.FormContact.controls.contactBetreff.hasError('required');
      ///Check Input "nachricht"
      this.contactFormValid.inputNachricht = this.FormContact.controls.contactMessage.hasError('required');
    }
  }
}
