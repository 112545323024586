import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { StartseiteComponent } from './_views/startseite/startseite.component';
import { ProjektComponent } from './_views/projekt/projekt.component';
import { ImpressumComponent } from './_views/impressum/impressum.component';
import { DatenschutzComponent } from './_views/datenschutz/datenschutz.component';
import { JobsComponent } from './_views/jobs/jobs.component';
import { JobPageComponent } from './_views/job-page/job-page.component';
import { NotFoundComponentComponent } from './_views/not-found-component/not-found-component.component';

const routes: Routes = [
  { path: '', component: StartseiteComponent},
  { path: 'projekt/:id', component: ProjektComponent},
  { path: 'impressum', component: ImpressumComponent},
  { path: 'datenschutz', component: DatenschutzComponent},
  { path: 'jobs', component: JobsComponent},
  { path: 'job/:id', component: JobPageComponent},
  { path: '**', component: NotFoundComponentComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
