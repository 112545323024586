import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-scroll-bar',
  templateUrl: './scroll-bar.component.html',
  styleUrls: ['./scroll-bar.component.sass']
})
export class ScrollBarComponent implements OnInit {
  @Input() scollTop: number;
  constructor() {
    this.scollTop = 0;
  }

  ngOnInit() {
  }

}
