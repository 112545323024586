import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.sass']
})
export class NavComponent implements OnInit {
  @Input() navItem;
  public number: number;
  constructor() {
    this.number = 10
  }

  ngOnInit() {
  }

}
