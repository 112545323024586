import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DirectusService } from 'src/app/_service/directus.service';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.sass']
})
export class JobsComponent implements OnInit {
  public jobList: object[] = [];
  deviceInfo = null;
  isMobile;
  constructor(private ds: DirectusService, private router: Router, private deviceService: DeviceDetectorService) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
  }

  async ngOnInit() {
    this.jobList = await this.ds.getJobs();
    console.log(this.jobList);
    window.scrollTo(0,0);
  }
  public routeToJob(event, id):void {
    console.log(event);
    this.router.navigateByUrl(`job/${id}`)
  }
}
