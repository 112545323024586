import { Injectable } from '@angular/core';
import DirectusSDK from "@directus/sdk-js";
@Injectable({
  providedIn: 'root'
})
export class DirectusService {
  private client = new DirectusSDK({
    url: "https://bauplanung-kuschewski.de/directus/public",
    project: "kuschewski",
    mode: 'jwt'
  });

  public mitarbeiterData: any[] = [];
  public leistungenData: any[] = [];
  public projekteData: any[] = [];
  public stellenangeboteData: any[] = [];
  constructor() { }

  async getMitarbeiter() {
    if(this.mitarbeiterData.length === 0){
      this.mitarbeiterData = await (await this.client.getItems('mitarbeiter')).data;
      this.mitarbeiterData.forEach(item => {
        let fileID: any = item.mitarbeiter_image;
        this.client.getFiles({
          filter: {
            id: {
              'eq': fileID
            }
          }
        }).then(data => {item.mitarbeiter_image = data.data[0]});
      })
      return this.mitarbeiterData;
      } else {
        return this.mitarbeiterData;
      }
  }
  async getLeistungen() {
    if(this.leistungenData.length === 0){
      this.leistungenData = await (await this.client.getItems('leistungen')).data;
      console.log(this.leistungenData);
      this.leistungenData.forEach(item => {
        let fileID: any = item.image;
        this.client.getFiles({
          filter: {
            id: {
              'eq': fileID
            }
          }
        }).then(data => {item.image = data.data[0]});
      })
      return this.leistungenData;
      } else {
        return this.leistungenData;
      }
  }
  async getProjekte() {
    if(this.projekteData.length === 0){
      this.projekteData = await (await this.client.getItems('projekte')).data;
      this.projekteData.forEach(item => {
        let fileID: any = item.projekt_image;
        this.client.getFiles({
          filter: {
            id: {
              'eq': fileID
            }
          }
        }).then(data => {item.projekt_image = data.data[0]});
      })
      return this.projekteData;
      } else {
        return this.projekteData;
      }
  }
  async getJobs() {
    if(this.stellenangeboteData.length === 0){
      this.stellenangeboteData = await (await this.client.getItems('stellenangebote')).data;
      return this.stellenangeboteData;
      } else {
        return this.stellenangeboteData;
      }
  }
  async getJobByID(id: number) {
    let singleJob = await (await this.client.getItem('stellenangebote',id)).data;
    return singleJob;
  }
  async getProjektById(id: number) {
    let result: any;
    result = await(await this.client.getItem('projekte', id, {
      fields: ['*.*']
    })).data;
    result.gallery.forEach(eintrag => {
      let fileID: number = eintrag.directus_files_id;
      this.client.getFiles({
        filter: {
          id: {
            'eq': fileID
          }
        }
      }).then(data => {eintrag.gallery_image = data.data[0]})
    });
    return result;
  }
}
