import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { ReactiveFormsModule} from '@angular/forms';
import { DirectusService } from './_service/directus.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './_layout/header/header.component';
import { NavComponent } from './_layout/header/nav/nav.component';
import { StartseiteComponent } from './_views/startseite/startseite.component';
import { ScrollBarComponent } from './_layout/scroll-bar/scroll-bar.component';
import { FooterComponent } from './_layout/footer/footer.component';
import { ProjektComponent } from './_views/projekt/projekt.component';
import { LeistungenComponent } from './_template/leistungen/leistungen.component';
import { CookieNoticeComponent } from './_template/cookie-notice/cookie-notice.component';
import { LogoComponent } from './_views/startseite/logo/logo.component';
import { ImpressumComponent } from './_views/impressum/impressum.component';
import { DatenschutzComponent } from './_views/datenschutz/datenschutz.component';
import { JobsComponent } from './_views/jobs/jobs.component';
import { JobPageComponent } from './_views/job-page/job-page.component';
import { NotFoundComponentComponent } from './_views/not-found-component/not-found-component.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavComponent,
    StartseiteComponent,
    ScrollBarComponent,
    FooterComponent,
    ProjektComponent,
    LeistungenComponent,
    CookieNoticeComponent,
    LogoComponent,
    ImpressumComponent,
    DatenschutzComponent,
    JobsComponent,
    JobPageComponent,
    NotFoundComponentComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    DeviceDetectorModule.forRoot(),
    ReactiveFormsModule,
  ],
  providers: [
    CookieService,
    DirectusService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
