import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DirectusService } from 'src/app/_service/directus.service';

@Component({
  selector: 'app-job-page',
  templateUrl: './job-page.component.html',
  styleUrls: ['./job-page.component.sass']
})
export class JobPageComponent implements OnInit {
  private id: number;
  public job: any = {};
  constructor(private activatedRoute: ActivatedRoute, private ds: DirectusService) {
    this.activatedRoute.params.subscribe(paramsId => {
      this.id = paramsId.id;
    });
    window.scrollTo(0,0);
  }

  async ngOnInit() {
    this.job = await this.ds.getJobByID(this.id);
    console.log(this.job);
  }

}
