import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import * as AOS from 'aos';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit{
  title = 'kuschewski - Bauplanungsbüro';
  deviceInfo = null;
  isMobile;

  constructor(private router: Router, route: ActivatedRoute, private deviceService: DeviceDetectorService) {
  }
  ngOnInit(): void {
    AOS.init({
      once: true
    });
    this.epicFunction();
  }
  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
  }
}