import { Component, OnInit } from '@angular/core';
import { Leistungen } from './leistungen';
import { trigger, state, style, animate, transition, animation, } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { DirectusService } from 'src/app/_service/directus.service';
@Component({
  selector: 'app-leistungen',
  templateUrl: './leistungen.component.html',
  styleUrls: ['./leistungen.component.sass'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translate(-100px,0)'}),
        animate('500ms 250ms', style({opacity: 1, transform: 'translate(0px,0)'}))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translate(0px,0)'}),
        animate('500ms', style({opacity: 0, transform: 'translate(-100px,0)'}))
      ])
    ])
  ]

})
export class LeistungenComponent implements OnInit {
  public leistungen: Leistungen[] = [];
  public activeLeistungen: number;
  constructor(private http: HttpClient, private ds: DirectusService) {
    this.activeLeistungen = 0;
  }

  async ngOnInit() {
    this.leistungen = await this.ds.getLeistungen();
  }
  public prevSlide(event?: any): void {
    if(this.activeLeistungen <= 0){
      this.activeLeistungen = (this.leistungen.length - 1)
    } else {
      this.activeLeistungen = this.activeLeistungen - 1;
    }
  }
  public nextSlide(event?: any): void {
    if(this.activeLeistungen >= (this.leistungen.length - 1)){
      this.activeLeistungen = 0;
    } else {
      this.activeLeistungen = this.activeLeistungen + 1;
    }

  }
}
